import React, { useRef } from 'react';

import {
  Box, Input, Slider, styled, Tooltip,
} from '@mui/material';

import { moneyFormat } from '../../services/Format';
import { MAX_VALUE_DUPLICATES_FILTER, MIN_VALUE_DUPLICATES_FILTER } from '../../utils/constants';

interface Props {
  filterId: string;
    range?: [number, number];
    rangeMinMax?: [number, number];
    handleIntervalRangeChange?: (range: [number, number], filterId: string) => void;
    disabled?: boolean
    label?: string;
}

interface PropsValueLabelComponent {
  children: React.ReactElement;
  value: number;
}

const RangeSlider = ({
  filterId,
  range = [0, 100],
  rangeMinMax = [MIN_VALUE_DUPLICATES_FILTER, MAX_VALUE_DUPLICATES_FILTER],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleIntervalRangeChange = () => {},
  disabled,
  label = '',
}: Props): JSX.Element => {
  const inputMinRef = useRef<HTMLInputElement>(null);
  const inputMaxRef = useRef<HTMLInputElement>(null);

  const handleChange = (_event: Event, newValue: number | number[]) => {
    handleIntervalRangeChange(newValue as [number, number], filterId);
  };

  const handleCursorPosition = (inputElement: HTMLInputElement, selectionStart?: number) => {
    const position = selectionStart ?? 3; // 3 positions the cursor right after the 'R$ '
    inputElement.focus();
    inputElement.setSelectionRange(position, position);
  };

  const getNumericValue = (strValue: string): number => {
    const cleanValue = strValue.replace(/\D/g, '');
    const numValue = Number(`${cleanValue.slice(0, -2)}.${cleanValue.slice(-2)}`);

    if (!numValue) return MIN_VALUE_DUPLICATES_FILTER;
    if (numValue < MIN_VALUE_DUPLICATES_FILTER) return MIN_VALUE_DUPLICATES_FILTER;
    if (numValue > MAX_VALUE_DUPLICATES_FILTER) return MAX_VALUE_DUPLICATES_FILTER;
    return numValue;
  };

  const handleChangeInputMin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, selectionStart } = event.target;
    const finalValue = getNumericValue(value);
    handleIntervalRangeChange([finalValue, range[1]], filterId);
    setTimeout(() => {
      if (inputMinRef.current) {
        handleCursorPosition(inputMinRef.current, selectionStart as number);
      }
    }, 1);
  };

  const handleChangeInputMax = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, selectionStart } = event.target;
    const finalValue = getNumericValue(value);
    handleIntervalRangeChange([range[0], finalValue], filterId);
    setTimeout(() => {
      if (inputMaxRef.current) {
        handleCursorPosition(inputMaxRef.current, selectionStart as number);
      }
    }, 1);
  };

  const ValueLabelComponent = (props: PropsValueLabelComponent) => {
    const { children, value } = props;

    return (
      <Tooltip enterTouchDelay={0} placement="bottom" title={value} arrow>
        {children}
      </Tooltip>
    );
  };

  const LabelStyle = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 12,
    position: 'absolute',
    top: -6,
    left: 16,
  }));

  const InputStyled = styled(Input)(({ theme }) => ({
    width: '140px',
    height: '100%',
    border: 'none',
    margin: '0 8px',
    '&:hover': {
      border: 'none',
      outline: 'none',
    },

    '& .MuiInput-input': {
      width: '100%',
      border: 'none',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 'none',
      padding: '0 4px',
      color: `${theme.palette.primary.dark}AA`,
      textAlign: 'center',
      letterSpacing: 0.5,
      transition: 'all 0.1 ease-out',
    },
  }));

  return (
    <Box sx={{ width: '100%', padding: '0 32px 0 24px' }} display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        flex={1}
        margin="0 32px 0 0"
      >
        De
        <InputStyled
          inputRef={inputMinRef}
          value={moneyFormat(range[0])}
          onChange={handleChangeInputMin}
        />
        até
        <InputStyled
          inputRef={inputMaxRef}
          value={moneyFormat(range[1])}
          onChange={handleChangeInputMax}
        />
      </Box>

      <LabelStyle>{label}</LabelStyle>
      <Slider
        sx={{ width: 200 }}
        min={rangeMinMax[0]}
        max={rangeMinMax[1]}
        disabled={disabled}
        value={range}
        onChange={handleChange}
        getAriaValueText={(value) => moneyFormat(value)}
        valueLabelDisplay="on"
        valueLabelFormat={(value) => moneyFormat(value)}
        components={{
          ValueLabel: ValueLabelComponent,
        }}
      />
    </Box>
  );
};

export default React.memo(RangeSlider);
