import React, { useState } from 'react';

import { Icon } from '@iconify/react';
import { Grid, IconButton } from '@mui/material';
import { saveAs } from 'file-saver';

import api from '../../api';
import { showSuccess } from '../../services/Toast';
import { IImport } from '../../types/dashboards';
import { FILES } from '../../utils/endpoints';
import MenuActions from './MenuActions';

interface IProps {
  row: any
}

const Actions: React.FC<IProps> = ({ row }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const rowData = row.row as IImport;

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(!isOpen);
  };

  const createAndDownloadXLSXFile = (fileData: string, fileTitle: string): void => {
    if (!fileData) throw new Error('Invalid file data. Could not create xlsx file and download it.');

    const xlsx = new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(xlsx, `${fileTitle}.xlsx`);
    showSuccess('Download do arquivo realizado com sucesso.', {
      autoClose: 3500,
      hideProgressBar: false,
    });
  };

  const handleClickFile = async () => {
    const file = await api.get(FILES(rowData.arquivo_identificador), {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'blob',
      },
    });

    createAndDownloadXLSXFile(file.data ?? '', rowData.arquivo_nome ?? '');
    handleClose();
  };

  const handleClickCriticism = async () => {
    const file = await api.get(FILES(rowData.arquivo_identificador_critica), {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'blob',
      },
    });

    createAndDownloadXLSXFile(file.data ?? '', `${rowData.arquivo_nome}` ?? '');
    handleClose();
  };

  return (
    <Grid container justifyContent="center" display="flex">
      <IconButton onClick={handleClick}>
        <Icon icon="mdi:download" style={{ color: '#02AD6F' }} />
      </IconButton>

      <MenuActions
        handleClose={handleClose}
        anchorEl={anchorEl}
        handleClickFile={handleClickFile}
        handleClickCriticism={handleClickCriticism}
        open={open}
        row={row.row}
      />
    </Grid>
  );
};

export default Actions;
