import React, { useMemo, ReactElement } from 'react';

import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

import useTenant from '../../../hooks/useTenant';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import shape from './shape';
import typography from './typography';

interface Props {
  children: ReactElement | ReactElement[];
}

export default function ThemeConfig({ children }: Props): ReactElement {
  const { colors } = useTenant();

  const themeOptions = useMemo(() => ({
    palette: {
      ...palette,
      primary: colors.PRIMARY,
    },
    shape,
    typography,
    shadows,
    customShadows,
  }), [colors]);

  const theme = createTheme(themeOptions as any);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
