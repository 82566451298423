import React from 'react';

import { Icon } from '@iconify/react';
import {
  IconButton,
  styled, Tooltip, tooltipClasses, TooltipProps,
} from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import moment from 'moment';

import palette from '../../assets/styles/theme/palette';
import { cnpjFormat, moneyFormat } from '../../services/Format';
import { ICard } from '../../types/dashboards';
import { FilterOption } from '../../types/filters';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#131313',
    color: '#FFF',
    maxWidth: 220,
    fontSize: 14,
    padding: '20px',
    border: '1px solid #dadde9',
  },
}));

const TABLE_COLUMNS_ARQUIVADOS: GridColDef[] = [{
  field: 'numero',
  headerName: 'Número',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
}, {
  field: 'sacado',
  headerName: 'Sacado',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
}, {
  field: 'sacado_documento',
  headerName: 'CNPJ Sacado',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => cnpjFormat(value as string),
}, {
  field: 'fornecedor',
  headerName: 'Fornecedor',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
}, {
  field: 'fornecedor_documento',
  headerName: 'CNPJ Fornecedor',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => cnpjFormat(value as string),
},
{
  field: 'valor',
  headerName: 'Valor',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => moneyFormat(value as number),
},
{
  field: 'vencimento',
  headerName: 'Vencimento',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => (value ? moment(value as string).format('DD/MM/YY') : '-'),
},
{
  field: 'importacao',
  headerName: 'Dt. Importação',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => (value ? moment(value as string).format('DD/MM/YY') : '-'),
},
{
  field: 'arquivamento',
  headerName: 'Dt. Arquivamento',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => (value ? moment(value as string).format('DD/MM/YY') : '-'),
},
{
  field: 'motivo',
  headerName: 'Motivo',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  filterable: false,
  sortable: false,
  renderCell: ({ row }) => (
    <IconButton disableRipple disabled={!row.motivo}>
      <HtmlTooltip arrow placement="left" title={row.motivo}>
        <Icon
          icon="bi:info-circle-fill"
          width={24}
          height={24}
          color={row.motivo ? palette.info.main : palette.grey[400]}
        />
      </HtmlTooltip>
    </IconButton>
  ),
},
];

const FILTER_OPTIONS_ARQUIVADOS: FilterOption[] = [{
  label: 'Período',
  id: 'periodo',
  type: 'date_range',
  disableOutOfRange: true,
}, {
  label: 'Número',
  id: 'numero',
  mask: 'number',
}, {
  label: 'Sacado',
  id: 'sacado_nome',
}, {
  label: 'CNPJ Sacado',
  id: 'sacado_documento',
  mask: 'cnpj',
}, {
  label: 'Fornecedor',
  id: 'fornecedor',
}, {
  label: 'CNPJ Fornecedor',
  id: 'fornecedor_documento',
  mask: 'cnpj',
}, {
  label: 'Valor',
  id: 'valor',
  type: 'interval_range',
}, {
  label: 'Vencimento',
  id: 'data_vencimento',
  type: 'date_range',
}, {
  label: 'Dt. Importação',
  id: 'data_importacao',
  type: 'date',
}, {
  label: 'Dt. Arquivamento',
  id: 'data_arquivamento',
  type: 'date',
}];

const VALUE_CARDS_ARQUIVADOS = (valor_total: number): ICard[] => [{
  id: 4,
  icon: 'uil:money-bill-slash',
  subtitle: 'Valor arquivado',
  value: valor_total,
  type: 'money',
}];

export {
  TABLE_COLUMNS_ARQUIVADOS,
  FILTER_OPTIONS_ARQUIVADOS,
  VALUE_CARDS_ARQUIVADOS,
};
