import React from 'react';

import {
  Grid,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import CardStyle from '../../assets/styles/CardStyle';
import Table from '../Table';

interface IStateProps {
  uploading: boolean
  loading: boolean,
  rows: Array<unknown>
  columns: Array<GridColDef>
}

const Datagrid: React.FC<IStateProps> = ({
  uploading, loading, rows, columns,
}) => (
  <Grid item xs={12} md={12} lg={12}>

    <CardStyle
      sx={{
        padding: 0,
        '&:hover': {
          transform: 'scale(101%)',
        },
      }}
    >
      <Table loading={loading} columns={columns} rows={rows} noRowsMsg="Nenhum registro encontrado." />
    </CardStyle>
  </Grid>
);

export default Datagrid;
