import React from 'react';

import {
  alpha,
  Menu, MenuItem, MenuProps, styled, Typography,
} from '@mui/material';

import shape from '../../assets/styles/theme/shape';

const MenuStyle = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: shape.borderRadiusMd,
    minWidth: 120,
    boxShadow: theme.shadows[8],
    '& .MuiMenu-list': {
      padding: '0px',
      color: theme.palette.primary.main,
      '& .MuiMenuItem-root': {
        justifyContent: 'space-between',
        padding: '12px 16px',
        '&:not(:last-child)': {
          borderBottom: `1px solid ${alpha(theme.palette.primary.light, 0.2)}`,
        },
      },
      '& .MuiMenuItem-root:hover': {
        animation: '$fadeUpIn 3000ms ease-in-out',
        transition: 'all .3s ease-in-out',
        backgroundColor: alpha(theme.palette.primary.light, 0.1),
      },
    },
  },
}));

interface IProps {
  anchorEl: null | HTMLElement,
  open: boolean,
  handleClickFile: () => void,
  handleClickCriticism: () => void,
  handleClose: () => void
  row: any;
}

const MenuActions: React.FC<IProps> = ({
  anchorEl, open, handleClickFile, handleClickCriticism, handleClose, row,
}): JSX.Element => (
  <MenuStyle
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
  >
    <MenuItem onClick={handleClickFile}>
      <Typography variant="subtitle2">
        Download Arquivo
      </Typography>
    </MenuItem>
    {!!row.possui_critica && (
    <MenuItem onClick={handleClickCriticism}>
      <Typography variant="subtitle2">
        Download Críticas
      </Typography>
    </MenuItem>
    )}
  </MenuStyle>
);

export default MenuActions;
