import { Reducer } from 'redux';

import { IConfigurationsState, ConfigurationsTypes } from './types';

const INITIAL_STATE: IConfigurationsState = {
  configuration: {
    title: '',
    colors: {
      colorBackground: '#576574',
      colorPrimary: '#000',
      colorSecondary: '#CCC',
      colorTertiary: '#AAA',
      colorNavbar: '#576574',
    },
    modules: [],
  },
};

const reducer: Reducer<IConfigurationsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConfigurationsTypes.SET_CONFIGURATIONS:
      return { ...state, configuration: action.payload };
    default: return state;
  }
};

export default reducer;
