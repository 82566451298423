import React from 'react';

import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import moment from 'moment';

import StatusFlagDashboard from '../../components/StatusFlag/StatusFlagDashboard';
import { cnpjFormat, moneyFormat } from '../../services/Format';
import { ICard } from '../../types/dashboards';
import { FilterOption, StatusOption } from '../../types/filters';

const TABLE_COLUMNS_NEGOCIACOES: GridColDef[] = [{
  field: 'numero',
  headerName: 'Número',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
}, {
  field: 'sacado',
  headerName: 'Sacado',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
}, {
  field: 'sacado_documento',
  headerName: 'CNPJ Sacado',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => cnpjFormat(value as string),
}, {
  field: 'fornecedor',
  headerName: 'Fornecedor',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
}, {
  field: 'fornecedor_documento',
  headerName: 'CNPJ Fornecedor',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => cnpjFormat(value as string),
},
{
  field: 'valor',
  headerName: 'Valor',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => moneyFormat(value as number),
},
{
  field: 'vencimento',
  headerName: 'Vencimento',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => moment(value as string).format('DD/MM/YY'),
},
{
  field: 'status_duplicata',
  headerName: 'Status',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  renderCell: (params) => (
    <StatusFlagDashboard status={params.value} />
  ),
}];

const FILTER_OPTIONS_NEGOCIACOES: FilterOption[] = [{
  label: 'Período',
  id: 'periodo',
  type: 'date_range',
  disableOutOfRange: true,
}, {
  label: 'Número',
  id: 'numero',
  mask: 'number',
}, {
  label: 'Sacado',
  id: 'sacado_nome',
}, {
  label: 'CNPJ Sacado',
  id: 'sacado_documento',
  mask: 'cnpj',
}, {
  label: 'Fornecedor',
  id: 'fornecedor_nome',
}, {
  label: 'CNPJ Fornecedor',
  id: 'fornecedor_documento',
  mask: 'cnpj',
}, {
  label: 'Valor',
  id: 'valor',
  type: 'interval_range',
}, {
  label: 'Vencimento',
  id: 'data_vencimento',
  type: 'date_range',
}, {
  label: 'Status',
  id: 'status',
  type: 'select',
}];

const STATUS_OPTIONS_NEGOCIACOES: StatusOption[] = [{
  label: 'Todos',
  value: null,
}, {
  label: 'A vencer',
  value: '0',
}, {
  label: 'Vencido',
  value: '1',
}, {
  label: 'Pago',
  value: '2',
}];

const VALUE_CARDS_NEGOCIACOES = ({
  valor_total, valores_a_vencer, valores_vencidos, valores_pagos,
}: {[key:string]: any}): ICard[] => [{
  id: 1,
  icon: 'uil:money-insert',
  subtitle: 'Valor operado',
  value: valor_total,
  type: 'money',
}, {
  id: 2,
  icon: 'uil:money-bill',
  subtitle: 'A Vencer',
  value: valores_a_vencer?.valor,
  percent: valores_a_vencer?.porcentagem,
  type: 'money',
}, {
  id: 3,
  icon: 'uil:money-bill-slash',
  subtitle: 'Vencido',
  value: valores_vencidos?.valor,
  percent: valores_vencidos?.porcentagem,
  type: 'money',
}, {
  id: 4,
  icon: 'uil:money-stack',
  subtitle: 'Pago',
  value: valores_pagos?.valor,
  percent: valores_pagos?.porcentagem,
  type: 'money',
}];

export {
  TABLE_COLUMNS_NEGOCIACOES,
  FILTER_OPTIONS_NEGOCIACOES,
  STATUS_OPTIONS_NEGOCIACOES,
  VALUE_CARDS_NEGOCIACOES,
};
