import React, { useEffect, useReducer, useState } from 'react';

import { Icon } from '@iconify/react';
import { DateRange } from '@mui/lab/DateRangePicker';
import {
  FormControl,
  Grid, InputLabel,
} from '@mui/material';
import moment from 'moment';

import useAuth from '../../hooks/useAuth';
import useFilter from '../../hooks/useFilter';
import { cnpjFormat } from '../../services/Format';
import { FilterOption, FiltersObject, SearchValue } from '../../types/filters';
import DatePicker from './DatePicker';
import RangeDatePicker from './RangeDatePicker';
import RangeSlider from './RangeSlider';
import SelectDropdown from './SelectDropdown';
import {
  ButtonStyle, CardStyle, DeleteIconStyle, OutlinedInputStyle,
} from './styles';

type State = FiltersObject;
type Action = {filter: keyof State | 'reset', value: SearchValue | null};

export interface FiltersProps {
  filterOptions: FilterOption[];
  selectDropdownOptions?: Array<{label: string, value: string | null}>;
  selectDropdownTitle?: string;
  handleAddFilter?: (filters: FiltersObject) => void
}

const Filter = ({
  filterOptions,
  selectDropdownOptions = [{ label: 'TESTE', value: 'TESTE' }],
  selectDropdownTitle = 'Status',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleAddFilter = () => {},
}: FiltersProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const { getMaskedValue } = useFilter();
  const { user, userVisualization } = useAuth();

  const getSacadoDocumento = (): string => {
    if (user?.cnpj === userVisualization?.cnpj) return '';
    return cnpjFormat(userVisualization?.cnpj || '');
  };

  const initialState: State = filterOptions.reduce((acc, cur) => {
    const { id, type } = cur;
    const defaultValue = () => {
      if (type === 'interval_range') return [0, 1_000_000];
      if (id === 'periodo') return [null, null];
      if (id === 'importado_em') return [null, null];
      if (id === 'sacado_documento') return getSacadoDocumento();
      if (type) return null;
      return '';
    };
    const newObj = { ...acc, [id]: { ...cur, value: defaultValue() } };
    return newObj;
  }, {});

  const stateReducer = (state: State, action: Action) => {
    const { filter, value } = action;

    switch (filter) {
      case 'reset':
        return initialState;
        break;
      default:
        return {
          ...state,
          [filter]: { ...state[filter], value },
        };
    }
  };

  const [state, dispatch] = useReducer(stateReducer, initialState);

  useEffect(() => {
    handleAddFilter(state);
  }, []);

  const handleClickFilter = () => {
    setOpen(!open);
  };

  const handleInputChange = (
    value: SearchValue,
    filter: string,
    mask?: string | undefined,
  ) => {
    const cleanValue = mask ? getMaskedValue(value as string, mask) : value;
    dispatch({ filter, value: cleanValue });
  };

  // update sacado_documento filter when userVisualization changes and search api
  useEffect(() => {
    const newCnpj = user?.cnpj === userVisualization?.cnpj ? '' : userVisualization?.cnpj;
    handleInputChange(newCnpj || '', 'sacado_documento', 'cnpj');
    handleAddFilter({
      ...state,
      sacado_documento: {
        ...state.sacado_documento,
        value: cnpjFormat(newCnpj),
      },
    });
  }, [userVisualization]);

  const handleCleanFilters = () => {
    dispatch({ filter: 'reset' as const, value: null });
    handleAddFilter(initialState);
  };

  return (
    <Grid container columns={{ xs: 1 }} spacing={2}>
      <Grid item xs={1} maxWidth="600px" display="flex" gap={2}>
        <CardStyle style={{ width: '280px' }}>
          <ButtonStyle
            disableRipple
            endIcon={<Icon width={20} icon={open ? 'ci:filter' : 'bx:filter-alt'} />}
            onClick={handleClickFilter}
            style={{ width: '100%', height: '100%' }}
          >
            Filtros&nbsp;
          </ButtonStyle>
        </CardStyle>
        {!!open && (
          <CardStyle>
            <ButtonStyle
              sx={{ padding: '0 20px', height: '100%' }}
              onClick={handleCleanFilters}
              endIcon={<DeleteIconStyle width={20} icon="mdi:close-thick" />}
            >
              Limpar Filtros
            </ButtonStyle>
          </CardStyle>
        )}
      </Grid>
      {open && (
        <Grid item container columns={1} spacing={2}>
          <Grid item xs={1} display="flex" flexWrap="wrap" flexDirection="row" gap={2}>
            {filterOptions.map(({
              id, label, type, mask, disableOutOfRange,
            }) => (
              <FormControl>
                <CardStyle key={id} style={{ width: type === 'date_range' ? '350px' : 'auto' }}>
                  {type === 'date' && (
                  <DatePicker
                    filterId={id}
                    label={label}
                    dateSelected={state[id].value as Date}
                    handleDateChange={handleInputChange}
                  />
                  )}
                  {type === 'date_range' && (
                  <RangeDatePicker
                    filterId={id}
                    label={label}
                    rangeDateSelected={state[id].value as DateRange<Date | null>}
                    handleDateRangeChange={handleInputChange}
                    disableOutOfRange={disableOutOfRange}
                  />
                  )}
                  {type === 'interval_range' && (
                  <RangeSlider
                    filterId={id}
                    label={label}
                    range={state[id].value as [number, number]}
                    rangeMinMax={[0, 1_000_000]}
                    handleIntervalRangeChange={handleInputChange}
                  />
                  )}
                  {type === 'select' && (
                  <SelectDropdown
                    filterId={id}
                    options={selectDropdownOptions}
                    title={selectDropdownTitle}
                    setSelectedValue={handleInputChange}
                  />
                  )}

                  {!type && (
                  <>
                    <InputLabel htmlFor={`outlined-adornment-${label}`}>{label}</InputLabel>
                    <OutlinedInputStyle
                      label={label}
                      id={`outlined-adornment-${label}`}
                      value={state[id].value}
                      onChange={(event) => handleInputChange(event.target.value, id, mask)}
                      disabled={id === 'sacado_documento' && (user?.cnpj !== userVisualization?.cnpj)}
                    />
                  </>
                  )}
                </CardStyle>
              </FormControl>
            ))}

          </Grid>

          <Grid item xs={1} marginBottom={2}>
            <CardStyle style={{ maxWidth: '280px' }}>
              <ButtonStyle
                sx={{ padding: '0 20px', height: '100%', width: '100%' }}
                onClick={() => handleAddFilter(state)}
              >
                Pesquisar
              </ButtonStyle>
            </CardStyle>
          </Grid>

        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
