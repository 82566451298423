import { useEffect, useRef, useState } from 'react';

import { GridColDef } from '@mui/x-data-grid';
import axios, {
  AxiosRequestConfig, CancelTokenSource,
} from 'axios';

import api from '../../api';
import {
  ICard, IDuplicate, IImport,
} from '../../types/dashboards';
import { FilterOption, FiltersObject, StatusOption } from '../../types/filters';
import { ABA_NUMBER, PAGE_CHANGE_CANCEL_REQUEST_MESSAGE } from '../../utils/constants';
import { DUPLICATES, IMPORTS_URL } from '../../utils/endpoints';
import useFilter from '../useFilter';
import { FILTER_OPTIONS_ARQUIVADOS, TABLE_COLUMNS_ARQUIVADOS, VALUE_CARDS_ARQUIVADOS } from './DataArquivados';
import { FILTER_OPTIONS_DISPONIVEIS, TABLE_COLUMNS_DISPONIVEIS, VALUE_CARDS_DISPONIVEIS } from './DataDisponiveis';
import { FILTER_OPTIONS_IMPORTS, TABLE_COLUMNS_IMPORTS } from './DataImports';
import {
  FILTER_OPTIONS_NEGOCIACOES,
  STATUS_OPTIONS_NEGOCIACOES,
  TABLE_COLUMNS_NEGOCIACOES,
  VALUE_CARDS_NEGOCIACOES,
} from './DataNegociacoes';

interface UseDashboardDataReturn {
    TABLE_ROWS: IDuplicate[] | IImport[];
    TABLE_COLUMNS: GridColDef[];
    VALUE_CARDS: ICard[];
    FILTER_OPTIONS: FilterOption[];
    STATUS_OPTIONS?: StatusOption[];
    isLoadingData: boolean;
}

type Aba = 0 | 1 | 2 | 99;

const useDashboardData = (
  aba: Aba,
  filters: FiltersObject,
): UseDashboardDataReturn => {
  const [duplicates, setDuplicates] = useState<IDuplicate[]>([]);
  const [imports, setImports] = useState<IImport[]>([]);

  const [cards, setCards] = useState<{[key: string]: any}>({});
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const cancelRequestTokenSource = useRef<CancelTokenSource>();

  const { getFilterQuery } = useFilter();

  const isGetDuplicates = aba === 0 || aba === 1 || aba === 2;
  const isGetImports = aba === 99;

  const getDuplicates = async (): Promise<void> => {
    setIsLoadingData(true);
    const filterParams = await getFilterQuery(filters);
    cancelRequestTokenSource.current = axios.CancelToken.source();

    api.get(`${DUPLICATES}?aba=${aba}&${filterParams}`, {
      cancelToken: cancelRequestTokenSource.current?.token,
    } as AxiosRequestConfig)
      .then((res) => {
        // eslint-disable-next-line
          const didUserChangePageAndCancelRequest = (cancelRequestTokenSource.current?.token.reason?.message === PAGE_CHANGE_CANCEL_REQUEST_MESSAGE);

        if (didUserChangePageAndCancelRequest) return;

        if (!res?.data) throw new Error('Could not retrieve data.');
        const {
          fornecedores,
          valor_total,
          valores_a_vencer,
          valores_pagos,
          valores_vencidos,
          duplicatas,
        } = res.data;

        setDuplicates(duplicatas ?? []);
        setCards({
          fornecedores,
          valor_total,
          valores_a_vencer,
          valores_pagos,
          valores_vencidos,
        });
      })
      .catch((err) => console.error('ERRO', err))
      .finally(() => setIsLoadingData(false));
  };

  const getImports = async (): Promise<void> => {
    setIsLoadingData(true);

    const filterQuery = await getFilterQuery(filters);

    api.get(`${IMPORTS_URL}?${filterQuery}`, {
      cancelToken: cancelRequestTokenSource.current?.token,
    } as AxiosRequestConfig)
      .then((res) => {
        // eslint-disable-next-line
        const didUserChangePageAndCancelRequest = (cancelRequestTokenSource.current?.token.reason?.message === PAGE_CHANGE_CANCEL_REQUEST_MESSAGE);

        if (didUserChangePageAndCancelRequest) return;

        const { data } = res;
        setImports(data.reverse());
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoadingData(false));
  };

  const getData = (): void => {
    if (isGetDuplicates && filters?.periodo) {
      getDuplicates();
    }

    if (isGetImports && filters?.importado_em) {
      getImports();
    }
  };

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => () => {
    // cancel request when user goes to a different page
    cancelRequestTokenSource.current?.cancel(PAGE_CHANGE_CANCEL_REQUEST_MESSAGE);
  }, []);

  const DATA_BY_ABA = {
    0: {
      columns: TABLE_COLUMNS_DISPONIVEIS,
      filters: FILTER_OPTIONS_DISPONIVEIS,
      cards: VALUE_CARDS_DISPONIVEIS(cards.fornecedores, cards.valor_total),
    },
    1: {
      columns: TABLE_COLUMNS_NEGOCIACOES,
      filters: FILTER_OPTIONS_NEGOCIACOES,
      cards: VALUE_CARDS_NEGOCIACOES(cards),
    },
    2: {
      columns: TABLE_COLUMNS_ARQUIVADOS,
      filters: FILTER_OPTIONS_ARQUIVADOS,
      cards: VALUE_CARDS_ARQUIVADOS(cards.valor_total),
    },
    99: {
      columns: TABLE_COLUMNS_IMPORTS,
      filters: FILTER_OPTIONS_IMPORTS,
      cards: [] as ICard[],
    },
  };

  const TABLE_ROWS = isGetDuplicates ? duplicates as IDuplicate[] : imports as IImport[];
  const TABLE_COLUMNS = DATA_BY_ABA[aba as Aba].columns as GridColDef[];
  const FILTER_OPTIONS = DATA_BY_ABA[aba as Aba].filters as FilterOption[];
  const VALUE_CARDS = DATA_BY_ABA[aba as Aba].cards as ICard[];
  const STATUS_OPTIONS = aba === ABA_NUMBER.NEGOCIACOES ? STATUS_OPTIONS_NEGOCIACOES : undefined;

  return {
    TABLE_ROWS,
    TABLE_COLUMNS,
    VALUE_CARDS,
    FILTER_OPTIONS,
    STATUS_OPTIONS,
    isLoadingData,
  };
};

export default useDashboardData;
