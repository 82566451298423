import React, { createContext, useEffect, useState } from 'react';

import api from '../api';
import { TENANT_CONFIG } from '../utils/endpoints';

type Logos = {
  logo: string,
    logoWhite: string
}

type Colors = {
  PRIMARY: {
    [key: string]: string
  }
}

export interface ITenantContext {
  colors: Colors,
  logos: Logos
  favicon: string
}

const TenantContext = createContext<ITenantContext>({} as ITenantContext);

export const TenantProvider: React.FC = ({ children }) => {
  const [logos, setLogos] = useState<Logos>({ logo: '', logoWhite: '' });
  const [favicon, setFavicon] = useState<string>('');
  const [colors, setColors] = useState<Colors>({
    PRIMARY: {
      lighter: '#DFE3E8',
      light: '#C4CDD5',
      main: '#919EAB',
      dark: '#637381',
      darker: '#454F5B',
      contrastText: '#FFFFFF',
    },
  });

  function shadeColor(color: string, percent: number) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100 as any, 10);
    G = parseInt((G * (100 + percent)) / 100 as any, 10);
    B = parseInt((B * (100 + percent)) / 100 as any, 10);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    const RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16));
    const GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16));
    const BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16));

    return `#${RR}${GG}${BB}`;
  }

  const getConfigurations = async () => {
    api.get(TENANT_CONFIG)
      .then((res) => {
        const {
          logo, logo_white: logoWhite, favicon, color_primary,
        } = res?.data;
        setFavicon(favicon);
        setLogos({ logo, logoWhite });
        setColors({
          PRIMARY: {
            lighter: shadeColor(color_primary, 120),
            light: shadeColor(color_primary, 60),
            main: color_primary,
            dark: shadeColor(color_primary, -60),
            darker: shadeColor(color_primary, -120),
            contrastText: '#fff',
          },
        });
      });
  };

  useEffect(() => {
    getConfigurations();
  }, []);

  return (
    <TenantContext.Provider
      value={{
        colors,
        logos,
        favicon,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

export default TenantContext;
