import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { customShadows } from '../../assets/styles/theme/shadows';
import shape from '../../assets/styles/theme/shape';

export const RootStyle = styled('div')(() => ({
  overflow: 'hide',
  width: '100%',
  backgroundColor: '#FFF',
  animation: '$fadeUpIn 3000ms ease-in-out',
  boxShadow: customShadows.z8,
  transition: 'all .3s ease-in-out',
  borderRadius: shape.borderRadiusMd,
}));

export const SkeletonContainerStyle = styled('div')(() => ({
  paddingLeft: 10,
  paddingRight: 10,
  alignItems: 'center',
}));

export const SkeletonFooterStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const SkeletonHeaderStyle = styled(Skeleton)(() => ({
  marginBottom: 5,
  padding: 40,
}));

export const SkeletonItemStyle = styled(Skeleton)(() => ({
  marginBottom: 4,
  padding: 20,
}));

export const SkeletonFooterItemStyle = styled(Skeleton)(() => ({
  width: 100,
  padding: 10,
  alignSelf: 'flex-end',
}));

export const NoRowsStyle = styled('div')(() => ({
  height: 650,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
}));
