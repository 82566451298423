import React from 'react';

import { Icon } from '@iconify/react';
import {
  Box, Skeleton, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import CardStyle from '../../assets/styles/CardStyle';
import { decimalFormat, moneyFormat } from '../../services/Format';

type CardProps = {
  value: string | number,
  subtitle: string,
  icon: string,
  type: 'money' | 'integer',
  percent?: number,
}

interface Props {
  card: CardProps;
  isLoading?: boolean;
}

const IconWrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(7),
  height: theme.spacing(5.5),
  marginBottom: theme.spacing(1),
  justifyContent: 'center',
  color: '#FFF',
  backgroundImage: `linear-gradient(135deg, 
    ${alpha(theme.palette.primary.main, 0.9)} 0%, 
    ${alpha(theme.palette.primary.main, 0.3)} 100%)`,
}));

const CardComponent = ({ card, isLoading = false }: Props): JSX.Element => {
  const {
    value, subtitle, icon, type, percent,
  } = card;
  const theme = useTheme();
  const hasReachedBreakPoint = useMediaQuery('(max-width:1100px)');
  const valor = type === 'money' ? moneyFormat(value) : value;

  return (
    <Box
      height="100px"
      width={hasReachedBreakPoint ? '100%' : '280px'}
      display="flex"
      justifyContent="center"
    >
      <CardStyle style={{ width: '100%', padding: '16px 32px' }}>
        <Box width="100%" display="flex" justifyContent={hasReachedBreakPoint ? 'center' : 'flex-start'} alignItems="center">
          <IconWrapperStyle style={{ marginTop: 6 }}>
            <Icon icon={icon} width={28} height={28} />
          </IconWrapperStyle>
          <Box marginLeft={2} width="195px">
            <Typography
              variant="subtitle1"
              style={{ color: theme.palette.primary.dark, margin: '0 !important', textAlign: 'left' }}
            >
              {subtitle}
            </Typography>
            <Typography variant="h4" sx={{ opacity: 0.82, textAlign: 'left' }}>
              {isLoading ? <Skeleton width={80} height={50} /> : valor}
            </Typography>
            {(percent && percent > 0) ? (
              <Typography variant="body2" sx={{ opacity: 0.82, textAlign: 'left' }}>
                {isLoading ? <Skeleton width={80} height={20} /> : `${decimalFormat(percent)} da carteira` }
              </Typography>
            ) : null}
          </Box>
        </Box>
      </CardStyle>
    </Box>
  );
};

export default CardComponent;
