import React from 'react';

import { Popover as MUIPopover, SxProps, Theme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { customShadows } from '../../assets/styles/theme/shadows';
import shape from '../../assets/styles/theme/shape';

const IconStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));

interface IProps {
  children: React.ReactNode,
  // eslint-disable-next-line react/require-default-props
  sx?: SxProps<Theme> | undefined,
  open: boolean,
  onClose: () => void,
  anchorEl: Element | ((element: Element) => Element) | null | undefined
}

const Popover: React.FC<IProps> = ({ children, sx, ...other }) => (
  <MUIPopover
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    PaperProps={{
      sx: {
        mt: 1.5,
        ml: 0.5,
        overflowY: 'scroll',
        overflowX: 'hidden',
        boxShadow: () => customShadows.z8,
        border: (theme) => `solid 1px ${theme.palette.primary.main}66`,
        borderRadius: () => `${shape.borderRadiusMd}px`,
        width: 220,
        ...sx,
      },
    }}
    {...other}
  >
    <IconStyle className="arrow" />
    {children}
  </MUIPopover>
);

export default Popover;
