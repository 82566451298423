import React from 'react';

import {
  getGridStringOperators, GridColDef, GridValueFormatterParams, GridValueGetterParams,
} from '@mui/x-data-grid';
import moment from 'moment';

import StatusFlagImports from '../../components/StatusFlag/StatusFlagImports';
import { moneyFormat } from '../../services/Format';

export const menuItems = [{
  label: 'Painel',
  href: '/dashboard',
}];

export const EOperacoesStatus: { [i: number]: string } = {
  0: 'Criado',
  1: 'Pre Aprovado',
  2: 'Analisando Sacados',
  3: 'Aprovado',
  4: 'Processando Pagamento',
  5: 'Crédito efetuado',
  6: 'Rejeitado',
  7: 'Cancelado',
};

export const STATUS = [{
  id: '0',
  name: 'Disponível',
}, {
  id: '1',
  name: 'Cedido',
}, {
  id: '2',
  name: 'A vencer',
}, {
  id: '3',
  name: 'Vencido',
}, {
  id: '4',
  name: 'Pago',
}];

export const COLUMN_ID: { [i: string]: number } = {
  numero: 0,
  'operacao.tomador.razaoSocial': 1,
  valor: 2,
  vencimento: 3,
  status: 4,
};

export const columns: GridColDef[] = [{
  field: 'numero',
  headerName: 'Número',
  disableColumnMenu: true,
  align: 'center',
  flex: 1,
  headerAlign: 'center',
  // valueGetter: (data: any) => data.row.operacao.codigo,
}, {
  field: 'operacao.tomador.razaoSocial',
  headerName: 'Fornecedor',
  disableColumnMenu: true,
  flex: 1,
  align: 'center',
  headerAlign: 'center',
  valueGetter: (data: GridValueGetterParams) => data.row.fornecedor,
}, {
  field: 'valor',
  headerName: 'Valor',
  disableColumnMenu: true,
  flex: 1,
  align: 'center',
  headerAlign: 'center',
  valueFormatter: ({ value }: GridValueFormatterParams) => moneyFormat(value),
}, {
  field: 'vencimento',
  headerName: 'Vencimento',
  disableColumnMenu: true,
  flex: 1,
  align: 'center',
  headerAlign: 'center',
  valueFormatter: ({ value }: any) => moment(value).format('DD/MM/YY'),
}, {
  field: 'status',
  headerName: 'Status',
  flex: 1,
  align: 'center',
  headerAlign: 'center',
  filterable: true,
}];

export interface Cards {
  id: number;
  icon: string;
  subtitle: string;
  value: number;
  type: 'money' | 'integer';
}

export const CARDS: Array<Cards> = [{
  id: 1,
  icon: 'uil:users-alt',
  subtitle: 'Fornecedores na base',
  value: 13465,
  type: 'integer',
}, {
  id: 2,
  icon: 'uil:money-insert',
  subtitle: 'Valor total operado',
  value: 47_616_271.76,
  type: 'money',
}, {
  id: 3,
  icon: 'uil:money-bill',
  subtitle: 'Valor operado no dia',
  value: 87_391.93,
  type: 'money',
}, {
  id: 4,
  icon: 'uil:money-bill-slash',
  subtitle: 'Valor disponível',
  value: 95_964_948.65,
  type: 'money',
}, {
  id: 4,
  icon: 'uil:money-stack',
  subtitle: 'Valor enviado',
  value: 518_522_047.40,
  type: 'money',
}, {
  id: 4,
  icon: 'uil:file-upload',
  subtitle: 'Uploads realizados',
  value: 22,
  type: 'integer',
}];

export const PAGE_SIZE = 10;

export const TABLE_COLUMNS: GridColDef[] = [{
  field: 'numero',
  headerName: 'Número',
  disableColumnMenu: true,
  align: 'center',
  flex: 1,
  headerAlign: 'center',
  // valueGetter: (data: any) => data.row.operacao.codigo,
}, {
  field: 'fornecedor',
  headerName: 'Fornecedor',
  disableColumnMenu: true,
  flex: 1,
  align: 'center',
  headerAlign: 'center',
  valueGetter: (data: GridValueGetterParams) => data.row.fornecedor,
}, {
  field: 'valor',
  headerName: 'Valor',
  disableColumnMenu: true,
  flex: 1,
  align: 'center',
  headerAlign: 'center',
  valueFormatter: ({ value }: GridValueFormatterParams) => moneyFormat(value),
}, {
  field: 'vencimento',
  headerName: 'Vencimento',
  disableColumnMenu: true,
  flex: 1,
  align: 'center',
  headerAlign: 'center',
  valueFormatter: ({ value }: any) => moment(value).format('DD/MM/YY'),
}, {
  field: 'status',
  headerName: 'Status',
  flex: 1,
  align: 'center',
  headerAlign: 'center',
  filterable: true,
  renderCell: (params) => (
    <StatusFlagImports status={params.value} />
  ),
}];

export const TABLE_ROWS = [{
  id: 1,
  numero: '1233',
  fornecedor: 'Joãozinho',
  valor: 312312,
  vencimento: '02/28/24',
  status: 20,
}, {
  id: 2,
  numero: '231',
  fornecedor: 'Bagual véio',
  valor: 712399,
  vencimento: '10/12/22',
  status: 0,
}, {
  id: 3,
  numero: '345',
  fornecedor: 'Maurição',
  valor: 12344,
  vencimento: '02/02/26',
  status: 10,
}, {
  id: 4,
  numero: '4',
  fornecedor: 'Felipão',
  valor: 12312,
  vencimento: '10/10/20',
  status: 10,
}, {
  id: 5,
  numero: '7676',
  fornecedor: 'Juninho',
  valor: 4314,
  vencimento: '06/03/24',
  status: 0,
}, {
  id: 6,
  numero: '23',
  fornecedor: 'Carlão',
  valor: 456457,
  vencimento: '12/08/22',
  status: 20,
}, {
  id: 7,
  numero: '555',
  fornecedor: 'Pedroca',
  valor: 12344,
  vencimento: '10/10/22',
  status: 10,
}, {
  id: 8,
  numero: '35',
  fornecedor: 'Pedroca',
  valor: 12344,
  vencimento: '10/10/22',
  status: 0,
}, {
  id: 9,
  numero: '35',
  fornecedor: 'Pedroca',
  valor: 12344,
  vencimento: '10/10/22',
  status: 0,
}, {
  id: 10,
  numero: '35',
  fornecedor: 'Pedroca',
  valor: 12344,
  vencimento: '10/10/22',
  status: 10,
}, {
  id: 11,
  numero: '35',
  fornecedor: 'Pedroca',
  valor: 12344,
  vencimento: '10/10/22',
  status: 20,
}];

export const IMPORT_CRITICISM_COLUMNS: GridColDef[] = [{
  field: 'mensagem',
  headerName: 'Mensagem',
  headerAlign: 'center',
  flex: 1,
  filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
  renderCell: (params) => (
    <div style={{
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word',
    }}
    >
      {params.value}
    </div>
  ),
}, {
  field: 'linha',
  headerName: 'Linha',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
}];

export const APPBAR_MOBILE = 60;
export const APPBAR_DESKTOP = 80;

export const USERS = [{
  name: 'Razao social',
  id: '1',
  document: '90616305000115',
  groupId: '123',
  email: 'email@email.com',
}, {
  name: 'Social Razão',
  id: '2',
  document: '68244119000143',
  groupId: '123',
  email: 'email@email.com',
}];

export const MIN_VALUE_DUPLICATES_FILTER = 0;
export const MAX_VALUE_DUPLICATES_FILTER = 1_000_000;

export const PAGE_CHANGE_CANCEL_REQUEST_MESSAGE = 'User changed pages.';

export const ABA_NUMBER = {
  DISPONIVEIS: 0 as const,
  NEGOCIACOES: 1 as const,
  ARQUIVADOS: 2 as const,
  IMPORTS: 99 as const,
};
