import { useContext } from 'react';

import AuthContext from '../contexts/auth';
import { IAuthContext } from '../contexts/types';

const useAuth = (): IAuthContext => {
  const auth = useContext(AuthContext);
  return auth;
};

export default useAuth;
