/**
 * Action Types
 */
export enum ConfigurationsTypes {
  SET_CONFIGURATIONS = '@configuration/SET_CONFIGURATIONS',
}

/**
 * Data Types
 */
interface Module {
  id: number,
  description: string
}

export interface IConfiguration {
  title: string,
  colors: {
    colorBackground: string,
    colorPrimary: string,
    colorSecondary: string,
    colorTertiary: string,
    colorNavbar: string,
  },
  modules: Module[],
}

/**
 * State Types
 */
export interface IConfigurationsState {
  readonly configuration: IConfiguration
}
