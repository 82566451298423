import { ISortModel } from '../../components/DataGrid/interfaces';

interface IParameters {
  groupId?: string | null | undefined,
  draweeDocument?: string | null | undefined,
  sortModel?: ISortModel | null | undefined
  searchText?: string | null
  statusId?: string | null
  page?: number | null
}

// AUTHENTICATION
export const LOGIN = 'autenticacao/login';
export const LOGIN_ADMIN = 'autenticacao/login-admin';
export const FORGOT_PASSWORD = 'autenticacao/esqueci-senha';
export const RESET_PASSWORD = 'autenticacao/resetar-senha';
export const ALTER_PASSWORD = 'autenticacao/alterar-senha';

// UPLOAD
export const UPLOAD_FILE_TITLES = 'importacoes';

// DRAWEE
export const DRAWEE = 'sacados';

export const EXPORT_CSV = 'duplicatas/exportar/csv';

// DASHBOARD
export const DASHBOARD = ({ groupId, draweeDocument }: IParameters): string => {
  if (groupId) {
    return `dashboard?grupo_id=${groupId}`;
  } if (draweeDocument) {
    return `dashboard?sacado_documento=${draweeDocument}`;
  }
  return 'dashboard';
};

// USERS
export const USUARIOS_ID = (id: string | undefined): string => `usuarios/${id}`;
export const USUARIOS_GROUP = (groupId: string | undefined): string => `usuarios/${groupId}/listar`;

// SACADOS
export const SACADOS = 'sacados/matrizes';
export const SACADOS_ID = (id: string | undefined): string => `sacados/${id}`;

// IMPORTS
export const IMPORTS_URL = 'importacoes';

// DUPLICATES
export const DUPLICATES = '/duplicatas';

// FILES
export const FILES = (id: string): string => `/arquivos/${id}/download`;

// TENANT_CONFIG
export const TENANT_CONFIG = 'configuracoes/tema-painel-sacado';
