import React, {
  useRef, useState,
} from 'react';

import { Icon } from '@iconify/react';
import {
  Button, Box, Divider, MenuItem, Typography, IconButton,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import { signOutService } from '../../api';
import useAuth from '../../hooks/useAuth';
import AlterPassword from '../../pages/AlterPassword';
import { cnpjFormat } from '../../services/Format';
import Popover from '../Popover';
import { BoxStyle, IconStyle } from './styles';

const Settings = (): JSX.Element => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useAuth();

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={onOpen}
        sx={{
          color: '#FFF',
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[200], 0.24),
            },
          }),
        }}
      >
        <Icon icon="ic:baseline-settings" />
      </IconButton>

      <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <BoxStyle>
          <Typography variant="subtitle1">
            {cnpjFormat(user?.cnpj)}
          </Typography>
          <Typography variant="body2">
            {user?.email}
          </Typography>
        </BoxStyle>

        <Divider sx={{ my: 1 }} />

        <MenuItem onClick={onOpenDialog}>
          <IconStyle icon="mdi:account-edit" />
          Alterar senha
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            onClick={signOutService}
            fullWidth
            color="error"
            variant="outlined"
          >
            Sair
          </Button>
        </Box>

      </Popover>
      <AlterPassword open={openDialog} onClose={onCloseDialog} />
    </>
  );
};

export default Settings;
