/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';

import {
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Spinner from '../../components/Spinner';
import useAuth from '../../hooks/useAuth';
import useTenant from '../../hooks/useTenant';
import { showError } from '../../services/Toast';
import { IApplicationState } from '../../store';
import { IConfiguration } from '../../store/ducks/configurations/types';
import './forgotPassword.scss';

interface IStyleProps {
  colorPrimary: string
}

interface IStateProps {
  configurations: IConfiguration
}

const useStyles = makeStyles<Theme, IStyleProps>(() => ({
  root: {
    width: '100%',
    marginBottom: 16,
    '& label.Mui-focused': {
      color: ({ colorPrimary }) => colorPrimary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: ({ colorPrimary }) => colorPrimary,
    },
    '&.MuiOutlinedInput-root': {
      '& :hover fieldset': {
        borderColor: ({ colorPrimary }) => colorPrimary,
      },
      '& .Mui-focused fieldset': {
        borderColor: ({ colorPrimary }) => colorPrimary,
      },
    },
  },
}));

const ForgotPassword: React.FC<IStateProps> = ({ configurations }) => {
  const classes = useStyles({ colorPrimary: configurations.colors.colorPrimary });

  const history = useHistory();
  const { forgotPassword, loading } = useAuth();

  const { logos: { logoWhite } } = useTenant();

  const [email, setEmail] = useState<string | null>(null);

  const handleContinue = useCallback(() => {
    if (!isEmpty(email)) {
      forgotPassword(email);
    } else {
      setEmail('');
      showError('Preenche os campos corretamente');
    }
  }, [email, forgotPassword]);

  const handleBack = () => {
    history.push('/login');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  return (
    <div id="page-forgotPassword">
      <Spinner active={loading} />
      <aside>
        <img width="50%" src={logoWhite} alt="lOGO" />
      </aside>
      <main>
        <div className="main-content">
          <h2>ESQUECI A SENHA</h2>
          <form noValidate autoComplete="off">
            <TextField
              helperText={email === '' ? 'Campo obrigatório' : null}
              className={classes.root}
              onChange={handleChange}
              required
              error={email === ''}
              label="E-mail"
              value={email}
            />
            <div className="buttons">
              <div className="back">
                <span onClick={handleBack}>
                  Voltar
                </span>
              </div>
              <button type="button" onClick={handleContinue}>
                Continuar
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  configurations: state.configurations.configuration,
});

export default connect(mapStateToProps)(ForgotPassword);
