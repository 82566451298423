import React, { useEffect, useState } from 'react';

import {
  Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';

import { SearchValue } from '../../types/filters';

interface Props {
  filterId: string;
  options?: Array<{ label: string; value: string | null }>;
  title?: string;
  setSelectedValue?: (value: SearchValue, filterId: string) => void;
  disabled?: boolean;
}

const SelectDropdown = ({
  filterId,
  options = [{ label: '', value: '' }],
  title = 'Status',
  setSelectedValue,
  disabled = false,
}: Props): JSX.Element => {
  const [selected, setSelected] = useState(options[0]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setSelected(event.target.value as any);
    if (setSelectedValue) {
      setSelectedValue(event.target.value as SearchValue, filterId);
    }
  };

  const getIndex = (value: string | null, arr: Array<any>) => arr.indexOf(
    arr.find((car) => car.value === value),
  );

  useEffect(() => {
    if (setSelectedValue) {
      setSelectedValue(selected, filterId);
    }
  }, []);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id={`${title}-select-label`}>{title}</InputLabel>
        <Select
          disabled={disabled}
          labelId={`${title}-select-label`}
          id={`${title}-select`}
          value={options[getIndex(selected.value, options) as number]}
          label={title}
          onChange={handleChange}
        >
          {options.map((option) => (
            // eslint-disable-next-line
            // @ts-ignore-next-line
            <MenuItem key={option.value} value={option}>{option.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectDropdown;
