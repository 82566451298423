/* eslint-disable @typescript-eslint/ban-types */
import React, {
  createContext, useCallback, useEffect, useState,
} from 'react';

import { useHistory } from 'react-router-dom';

import {
  alterPasswordService,
  forgotPasswordService,
  resetPasswordService,
  signInService,
} from '../api';
import useLocalStorage from '../hooks/useLocalStorage';
import {
  IAdminLogin,
  IAlterPassword,
  IAuthContext,
  IResetPassword,
  IUser,
  IUserLogin,
  IUserVisualization,
} from './types';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

// eslint-disable-next-line react/prop-types
export const AuthProvider: React.FC = ({ children }) => {
  const [storageUser, setStorageUser, removeStorageUser] = useLocalStorage('@authApp:user');
  const [storageToken, setStorageToken, removeStorageToken] = useLocalStorage('@authApp:token');
  const [
    storageUserVisualization,
    setStorageUserVisualization,
    removeStorageUserVisualization,
  ] = useLocalStorage('@authApp:userVisualization');
  const history = useHistory();
  const [user, setUser] = useState<IUser | null>(null);
  const [userVisualization, setUserVisualization] = useState<IUserVisualization | null>(null);
  const [loading, setLoading] = useState(true);

  // const { setUserVisualization } = useVisualization();

  useEffect(() => {
    if (storageUser && storageToken) {
      setUser(storageUser);
    }

    if (storageUserVisualization) {
      setUserVisualization(storageUserVisualization);
    }
    setLoading(false);
  }, []);

  const signIn = useCallback(async (values: IUserLogin | IAdminLogin, role?: 'user' | 'admin') => {
    setLoading(true);
    const response = await signInService(values, role);
    if (response) {
      const userV = {
        email: response?.user.email,
        grupo_id: response?.user.groupId,
        id: response?.user.id,
        razao_social: response?.user.name,
        cnpj: response.user.document,
      };
      setUser({ ...response?.user, cnpj: response?.user.document });
      setUserVisualization(userV);
      setStorageUserVisualization(userV);
      setStorageUser({ ...response?.user, cnpj: response?.user.document });
      setStorageToken(response?.token);
      history.push('/dashboard');
    }
    setLoading(false);
  }, []);

  const signOut = useCallback(() => {
    setLoading(true);
    removeStorageUser();
    removeStorageToken();
    removeStorageUserVisualization();
    setUserVisualization(null);
    setUser(null);
    setLoading(false);
  }, []);

  const forgotPassword = useCallback(async (email: string | null) => {
    setLoading(true);
    await forgotPasswordService(email);
    setLoading(false);
  }, []);

  const resetPassword = useCallback(async (values: IResetPassword) => {
    setLoading(true);
    const response = await resetPasswordService(values);
    if (response) {
      signInService(values);
    }
    setLoading(false);
    return response;
  }, []);

  const alterPassword = useCallback(async (values: IAlterPassword) => {
    setLoading(true);
    await alterPasswordService(values);
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!((user && user.email)),
        user,
        signIn,
        signOut,
        loading,
        forgotPassword,
        resetPassword,
        alterPassword,
        setUserVisualization,
        userVisualization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
