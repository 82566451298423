import React from 'react';

import { Tab } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface LinkTabProps {
  label: string;
  href: string;
}

export function LinkTab({ href, ...others }: LinkTabProps): JSX.Element {
  const history = useHistory();
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        history.push(href);
        event.preventDefault();
      }}
      {...others}
    />
  );
}
