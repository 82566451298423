import React from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import ThemeConfig from './assets/styles/theme';
import GlobalStyles from './assets/styles/theme/globalStyles';
// import { BaseOptionChartStyle } from './components/Chart/BaseOptionChart';
import { AuthProvider } from './contexts/auth';
import { TenantProvider } from './contexts/tenant';
import Routes from './routes';
import { ToastContainer } from './services/Toast';

const App: React.FC = () => (
  <HelmetProvider>
    <BrowserRouter>
      <AuthProvider>
        <TenantProvider>
          <ThemeConfig>
            <ToastContainer />
            <GlobalStyles />
            {/* <BaseOptionChartStyle /> */}
            <Routes />
          </ThemeConfig>
        </TenantProvider>
      </AuthProvider>
    </BrowserRouter>
  </HelmetProvider>
);

export default App;
