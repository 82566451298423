import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { filter, find } from 'lodash';
import localStorage from 'redux-persist/es/storage';

import {
  IAdminLogin, IAlterPassword, IResetPassword, IUser, IUserLogin,
} from '../contexts/types';
import history from '../routes/history';
// eslint-disable-next-line import/no-cycle
import { errorHandler } from '../services/Error';
import { showError, showSuccess } from '../services/Toast';
import {
  ALTER_PASSWORD, FORGOT_PASSWORD, LOGIN, LOGIN_ADMIN, RESET_PASSWORD,
} from '../utils/endpoints';

export const CONFIGURATION = {
  title: 'PAINEL SACADO',
  colors: {
    colorBackground: '#576574',
    colorPrimary: '#02AD6F',
    colorSecondary: '#CCC',
    colorTertiary: '#AAA',
    colorNavbar: '#576574',
  },
};

interface IResponseLogin {
  token: string | any;
  user: IUser | any;
}

export const getToken = async () => localStorage.getItem('@authApp:token');

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // timeout: 75000,
});

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = await getToken();

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }

  return config;
});

export async function signInService(
  values: IAdminLogin | IUserLogin,
  role?: 'admin' | 'user',
): Promise<IResponseLogin | null> {
  const isAdmin = role === 'admin' && 'email_sacado' in values;
  const url = role === 'admin' ? LOGIN_ADMIN : LOGIN;

  let data;

  if (isAdmin) {
    data = {
      email: values.email,
      senha: values.password,
      email_sacado: values?.email_sacado,
      origem: 7,
    };
  } else {
    data = {
      email: values.email,
      senha: values.password,
    };
  }

  return instance({
    method: 'POST',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url,
    data,
  }).then((resp) => {
    const clains = resp.data.usuarioToken.claims;
    const roles = filter(clains, { type: 'role' });
    const superUser = find(roles, { value: 'super-usuario' });
    return {
      token: resp.data.accessToken,
      user: {
        id: resp.data.usuarioToken.id,
        document: find(clains, { type: 'SACADO_DOCUMENTO' })?.value,
        name: find(clains, { type: 'SACADO_NOME' })?.value,
        email: values.email,
        roles,
        super: Boolean(superUser),
        groupId: find(clains, { type: 'GRUPO_ID' })?.value,
      },
    };
  }).catch((e) => {
    errorHandler(e);
    return null;
  });
}

export async function forgotPasswordService(
  email: string | null,
): Promise<void | null> {
  return instance({
    method: 'POST',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: FORGOT_PASSWORD,
    data: {
      email,
    },
  }).then((resp) => {
    if (resp.status === 200) {
      showSuccess('E-mail para recuperação de senha enviado com sucesso.');
    }
  }).catch((e) => {
    errorHandler(e);
  });
}

export async function resetPasswordService(values: IResetPassword): Promise<any> {
  return instance({
    method: 'POST',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: RESET_PASSWORD,
    data: {
      email: values.email,
      nova_senha: values.password,
      confirmacao_nova_senha: values.confirmPassword,
      token: values.token,
    },
  }).then((resp) => {
    if (resp.status === 200) {
      showSuccess('Senha definida com sucesso.');
      return resp;
    }
    return null;
  }).catch((e) => {
    errorHandler(e);
    return null;
  });
}

export async function alterPasswordService(values: IAlterPassword): Promise<any> {
  return instance({
    method: 'POST',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: ALTER_PASSWORD,
    data: {
      email: values.email,
      senha_atual: values.password,
      nova_senha: values.newPassword,
      confirmacao_nova_senha: values.confirmNewPassword,
    },
  }).then((resp) => {
    if (resp.status === 200) {
      showSuccess('Senha alterada com sucesso.');
    }
  }).catch((e) => {
    errorHandler(e);
  });
}

export const signOutService = (): void => {
  localStorage.removeItem('@authApp:token');
  localStorage.removeItem('@authApp:user');
  setTimeout(() => {
    history.replace('/login');
    window.location.reload();
  }, 500);
};

const fError = (e: AxiosError) => {
  if (e?.response?.status === 401) {
    showError('Usuário sem permissão, faça login novamente.');
    signOutService();
  } else {
    errorHandler(e);
  }
};

export default {
  get: (url: string, config?: AxiosRequestConfig): Promise<any> => instance({
    method: 'GET',
    url,
    ...config,
  }).catch((e) => {
    fError(e);
    return null;
  }),
  download: (url: string): Promise<any> => instance({
    method: 'GET',
    url,
  }).catch((e) => {
    fError(e);
    return null;
  }),
  upload: (url: string, data: FormData): Promise<any> => instance({
    method: 'POST',
    url,
    data,
    headers: {
      'content-type': 'multipart/form-data',
    },
  }).then((resp) => {
    if (resp.status === 200 || resp.status === 201) {
      showSuccess('Upload realizado com sucesso.');
    }
    return resp;
  }).catch((e) => {
    fError(e);
    return null;
  }),
};
