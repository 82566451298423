import * as React from 'react';

import { DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { ptBR } from 'date-fns/locale';

type ChangeInputFn = (date: Date | null, id: string) => void;

interface Props {
  filterId: string;
  dateSelected: Date | null;
  handleDateChange?: ChangeInputFn;
  disabled?: boolean
  label: string
}

const DatePicker = ({
  filterId, dateSelected, handleDateChange, label, disabled,
}: Props): JSX.Element => (
  <>
    {/* eslint-disable-next-line */}
      {/* @ts-ignore disable-next-line */}
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
      <DesktopDatePicker
        disabled={disabled}
        label={label}
        openTo="day"
        inputFormat="dd/MM/yy"
        mask="__/__/__"
        value={dateSelected}
        onChange={(date) => {
          if (handleDateChange) handleDateChange(date, filterId);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: 180 }}
            inputProps={
          { ...params.inputProps, placeholder: 'DD/MM/AA' }
        }
          />
        )}

      />
    </LocalizationProvider>
  </>
);

export default DatePicker;
