import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import history from './routes/history';
import { persistor, store } from './store';

import './assets/styles/global.scss';

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Route component={App} />
        </Router>
      </PersistGate>
    </Provider>,
    document.getElementById('root'),
  );
};

render();
