import React from 'react';

import { Icon } from '@iconify/react';
import { alpha, darken, styled } from '@mui/material';

import palette from '../../assets/styles/theme/palette';

interface Props {
  status: 0 | 1 | 2;
  containerStyles?: {[key: string]: any};
  contentStyles?: {[key: string]: any};
}

const StatusFlagDashboard = ({
  status = 0,
  containerStyles = {},
  contentStyles = {},
}: Props): JSX.Element => {
  const statusProperties = {
    0: { text: 'A vencer', color: palette.warning.main, icon: 'carbon:warning' },
    1: { text: 'Vencido', color: palette.error.main, icon: 'codicon:error' },
    2: { text: 'Pago', color: palette.primary.main, icon: 'dashicons:money-alt' },
  };

  const ICON_SIZE = 20;

  const Container = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    animation: '$fadeUpIn 3000ms ease-in-out',
    ...containerStyles,
  }));

  const Content = styled('div')(({ theme }) => ({
    width: 'fit-content',
    maxWidth: '130px',
    minWidth: '110px',
    height: 'fit-content',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    transition: 'all .3s ease-in-out',
    color: darken(statusProperties[status].color, 0.5),
    background: alpha(statusProperties[status].color, 0.1),
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(1),
    fontSize: 12,
    fontWeight: 'bold',
    ...contentStyles,
  }));

  const IconWrapper = styled('div')(({ theme }) => ({
    width: '20px',
    height: '100%',
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    transition: 'all .3s ease-in-out',
  }));

  const StatusText = styled('div')(() => ({
    display: 'flex',
    transition: 'all .3s ease-in-out',
  }));

  return (
    <Container className="flag-status__container">
      <Content>
        <IconWrapper>
          <Icon
            icon={statusProperties[status].icon}
            width={ICON_SIZE}
            height={ICON_SIZE}
            style={{ marginRight: 16 }}
            color={statusProperties[status].color}
          />
        </IconWrapper>
        <StatusText className="flag-status__text">{statusProperties[status].text}</StatusText>
      </Content>
    </Container>
  );
};

export default StatusFlagDashboard;
