import { DateRange } from '@mui/lab/DateRangePicker';
import moment from 'moment';

import { cnpjFormat, formatDateToISO8601, removeAllSpecialCharacters } from '../services/Format';
import { FiltersObject } from '../types/filters';

interface UseFilterReturn {
    getMaskedValue: (value: string, mask: string) => string;
    getFilterQuery: (filters: FiltersObject) => Promise<string>;
}

const useFilter = (): UseFilterReturn => {
  const getMaskedValue = (value: string, mask: string): string => {
    if (mask === 'number') {
      const onlyNumbers = value.replace(/\D/g, '');
      return onlyNumbers;
    }

    if (mask === 'cnpj') {
      const formatted = cnpjFormat(value.length > 18 ? value.slice(0, -1) : value);
      return formatted;
    }

    return value;
  };

  const getDateRangeQuery = (paramName: string, value: DateRange<Date>) => {
    const [start, finish] = value as DateRange<Date>;
    const formattedStart = formatDateToISO8601(start);
    const formattedFinish = formatDateToISO8601(finish);

    return `${paramName}_inicial=${formattedStart}&${paramName}_final=${formattedFinish}&`;
  };

  const getFilterQuery = async (filters: FiltersObject): Promise<string> => {
    let query = '';

    Object.entries(filters).forEach(([filterName, { value, id, mask }]): void => {
      if (value) {
        switch (filterName) {
          case 'periodo': {
            query += getDateRangeQuery('criado_as', value as DateRange<Date>);
            break;
          }
          case 'data_vencimento':
            query += getDateRangeQuery('data_vencimento', value as DateRange<Date>);
            break;
          case 'importado_em':
            query += getDateRangeQuery('data_importacao', value as DateRange<Date>);
            break;
          case 'valor': {
            const [min, max] = value as [number, number];
            query += `valor_minimo=${min}&valor_maximo=${max}&`;
            break;
          }
          case 'data_importacao':
          case 'data_arquivamento':
            query += `${id}=${moment(value as Date).toISOString()}&`;
            break;
          case 'status': {
            const statusValue = (value as {label: string; value: string | null}).value;
            query += statusValue ? `${id}=${statusValue}&` : '';
            break;
          }
          default: {
            const cleanValue = mask ? removeAllSpecialCharacters(value as string) : value;
            query += `${id}=${cleanValue}&`;
          }
        }
      }
    });

    return query;
  };

  return {
    getMaskedValue, getFilterQuery,
  };
};

export default useFilter;
