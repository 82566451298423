import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Arquivados from '../pages/Arquivados';
import Auth from '../pages/Auth';
import Disponiveis from '../pages/Disponiveis';
import ForgotPassword from '../pages/ForgotPassword';
import Imports from '../pages/Imports';
import Negociacoes from '../pages/Negociacoes';
import ResetPassword from '../pages/ResetPassword';
import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Auth} />
    <Route exact path="/login" component={Auth} />
    <Route exact path="/login-admin" component={Auth} />
    <Route exact path="/esqueci_senha" component={ForgotPassword} />
    <Route exact path="/resetar_senha" component={ResetPassword} />
    <PrivateRoute path="/dashboard" component={Disponiveis} />
    <PrivateRoute path="/arquivados" component={Arquivados} />
    <PrivateRoute path="/negociacoes" component={Negociacoes} />
    <PrivateRoute path="/importacoes" component={Imports} />
  </Switch>
);

export default Routes;
