/* eslint-disable react/prop-types */
import React from 'react';

import { DataGrid, ptBR } from '@mui/x-data-grid';

import shape from '../../assets/styles/theme/shape';
import {
  NoRowsStyle,
  RootStyle,
  SkeletonContainerStyle,
  SkeletonFooterItemStyle,
  SkeletonFooterStyle,
  SkeletonHeaderStyle,
  SkeletonItemStyle,
} from './styles';

interface IStateProps {
  columns: Array<any>
  loading: boolean
  rows: Array<any>
  noRowsMsg: string
  // eslint-disable-next-line react/require-default-props
  height?: number | string
}

const Table: React.FC<IStateProps> = ({
  loading, rows, columns, noRowsMsg, height = 650,
}) => {
  const noRows = () => (
    <NoRowsStyle>
      <text>{noRowsMsg}</text>
    </NoRowsStyle>
  );

  return (
    <RootStyle sx={{ height }}>
      {loading
        ? (
          <SkeletonContainerStyle>
            <SkeletonHeaderStyle animation="wave" />
            <div>
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
            </div>
            <SkeletonFooterStyle>
              <SkeletonFooterItemStyle animation="wave" />
            </SkeletonFooterStyle>
          </SkeletonContainerStyle>
        ) : (
          <DataGrid
            style={{ borderRadius: shape.borderRadiusMd }}
            getRowId={(row) => row.Id || row.id}
            components={{
              NoRowsOverlay: () => noRows(),
            }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            isRowSelectable={() => false}
            loading={loading}
            rows={rows}
            getRowHeight={(params) => {
              const mensagem = params?.model?.mensagem;

              if (mensagem && mensagem.length > 50) {
                // the number of lines needed to display the full message
                const linesNeeded = mensagem.length / 30;
                const lineHeight = 18;
                const rowHeight = lineHeight * linesNeeded;
                const finalRowHeight = rowHeight < 55 ? 55 : rowHeight;
                return finalRowHeight;
              }

              return null;
            }}
            columns={columns}
            disableColumnSelector
            pageSize={10}
          />
        )}
    </RootStyle>
  );
};

export default React.memo(Table);
