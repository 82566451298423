import React from 'react';

import { Icon } from '@iconify/react';
import { alpha, darken, styled } from '@mui/material';

import palette from '../../assets/styles/theme/palette';
import useHover from '../../hooks/useHover';

interface Props {
  status: 0 | 10 | 20;
}

const StatusFlagImports = ({ status = 0 }: Props): JSX.Element => {
  const [hoverRef, isHovered] = useHover();

  const statusProperties = {
    0: { text: 'Aguardando', color: palette.warning.main, icon: 'ci:clock' },
    10: { text: 'Processando', color: palette.info.main, icon: 'eos-icons:loading' },
    20: { text: 'Processado', color: palette.success.main, icon: 'akar-icons:circle-check-fill' },
  };

  const ICON_SIZE = 20;

  const Container = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    animation: '$fadeUpIn 3000ms ease-in-out',

  }));

  const Content = styled('div')(({ theme }) => ({
    width: '130px',
    height: 'fit-content',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    transition: 'all .3s ease-in-out',
    color: darken(statusProperties[status].color, 0.5),
    background: isHovered ? alpha(statusProperties[status].color, 0.1) : 'transparent',
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(1),
    fontSize: 12,
    fontWeight: 'bold',

  }));

  const IconWrapper = styled('div')(({ theme }) => ({
    width: '20px',
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    transition: 'all .3s ease-in-out',
  }));

  const StatusText = styled('div')(() => ({
    display: 'flex',
    transition: 'all .3s ease-in-out',
  }));

  return (
    <Container className="flag-status__container" ref={hoverRef as React.RefObject<HTMLDivElement>}>
      <Content>
        <IconWrapper>
          <Icon
            icon={statusProperties[status].icon}
            width={ICON_SIZE}
            height={ICON_SIZE}
            style={{ marginRight: 16 }}
            color={statusProperties[status].color}
          />
        </IconWrapper>
        <StatusText className="flag-status__text">{statusProperties[status].text}</StatusText>
      </Content>
    </Container>
  );
};

export default StatusFlagImports;
