/* eslint-disable react/prop-types */
import React, { Dispatch, SetStateAction } from 'react';

import { Icon } from '@iconify/react';
import {
  Box,
  CircularProgress, styled, Typography, useMediaQuery,
} from '@mui/material';
import Dropzone from 'react-dropzone';

import api from '../../api';
import CardStyle from '../../assets/styles/CardStyle';
import shape from '../../assets/styles/theme/shape';
import { UPLOAD_FILE_TITLES } from '../../utils/endpoints';

interface IStateProps {
  setUploading: Dispatch<SetStateAction<boolean>>
  uploading: boolean
}

const SectionStyle = styled('section')(() => ({
  border: '1px dashed',
  borderRadius: shape.borderRadiusMd,
  fontSize: 12,
  padding: 10,
  height: 182,
  width: '100%',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}));

const DivStyle = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 16px',
}));

const Upload: React.FC<IStateProps> = ({
  setUploading, uploading,
}) => {
  const uploadFile = async (acceptedFiles: File) => {
    setUploading(true);

    const formData = new FormData();
    formData.append('planilha', acceptedFiles);
    await api.upload(UPLOAD_FILE_TITLES, formData);

    setUploading(false);
  };

  const hasReachedBreakPoint = useMediaQuery('(max-width:1100px)');

  return (
    <Box height="100px" width={hasReachedBreakPoint ? '100%' : '340px'}>
      <CardStyle
        sx={{ padding: 0 }}
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <Dropzone
          multiple={false}
          onDrop={(acceptedFiles) => uploadFile(acceptedFiles[0])}
        >
          {({ getRootProps, getInputProps }) => (
            <SectionStyle>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {uploading ? <CircularProgress color="inherit" /> : (
                  <DivStyle>
                    <Icon icon="uil:file-upload" width={32} style={{ marginBottom: 10 }} />
                    <Typography variant="caption">
                      Arraste e solte a planilha aqui, ou clique para selecionar o arquivo
                    </Typography>
                  </DivStyle>
                )}
              </div>
            </SectionStyle>
          )}
        </Dropzone>
      </CardStyle>
    </Box>
  );
};

export default Upload;
