import React, { useState } from 'react';

import {
  Container, Grid,
} from '@mui/material';

import DataGrid from '../../components/DataGrid';
import Filter from '../../components/Filters';
import Page from '../../components/Page';
import Spinner from '../../components/Spinner';
import useTableData from '../../hooks/useDashboardData';
import { FiltersObject } from '../../types/filters';
import { ABA_NUMBER } from '../../utils/constants';

const Imports: React.FC = (): JSX.Element => {
  const [filters, setFilters] = useState<FiltersObject>({});

  const {
    TABLE_ROWS, FILTER_OPTIONS, TABLE_COLUMNS, isLoadingData,
  } = useTableData(ABA_NUMBER.IMPORTS, filters);

  const handleAddFilter = (filters: FiltersObject) => {
    setFilters(filters);
  };

  return (
    <Page title="Importações">
      <Spinner active={isLoadingData} />
      <Container maxWidth="xl">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item minWidth="100%">
            <Filter
              handleAddFilter={handleAddFilter}
              filterOptions={FILTER_OPTIONS}
            />
          </Grid>

          <DataGrid
            uploading={false}
            loading={isLoadingData}
            rows={TABLE_ROWS}
            columns={TABLE_COLUMNS}
          />
        </Grid>
      </Container>
    </Page>
  );
};

export default Imports;
