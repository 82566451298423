import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import moment from 'moment';

import { cnpjFormat, moneyFormat } from '../../services/Format';
import { ICard } from '../../types/dashboards';
import { FilterOption } from '../../types/filters';

const TABLE_COLUMNS_DISPONIVEIS: GridColDef[] = [{
  field: 'numero',
  headerName: 'Número',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
}, {
  field: 'sacado',
  headerName: 'Sacado',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
}, {
  field: 'sacado_documento',
  headerName: 'CNPJ Sacado',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => cnpjFormat(value as string),
}, {
  field: 'fornecedor',
  headerName: 'Fornecedor',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
}, {
  field: 'fornecedor_documento',
  headerName: 'CNPJ Fornecedor',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => cnpjFormat(value as string),
},
{
  field: 'valor',
  headerName: 'Valor',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => moneyFormat(value as number),
},
{
  field: 'vencimento',
  headerName: 'Vencimento',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => moment(value as string).format('DD/MM/YY'),
},
{
  field: 'importacao',
  headerName: 'Dt. Importação',
  align: 'center',
  headerAlign: 'center',
  flex: 1,
  disableColumnMenu: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => moment(value as string).format('DD/MM/YY'),
}];

const FILTER_OPTIONS_DISPONIVEIS: FilterOption[] = [{
  label: 'Período',
  id: 'periodo',
  type: 'date_range',
  disableOutOfRange: true,
}, {
  label: 'Número',
  id: 'numero',
  mask: 'number',
}, {
  label: 'Sacado',
  id: 'sacado_nome',
}, {
  label: 'CNPJ Sacado',
  id: 'sacado_documento',
  mask: 'cnpj',
}, {
  label: 'Fornecedor',
  id: 'fornecedor_nome',
}, {
  label: 'CNPJ Fornecedor',
  id: 'fornecedor_documento',
  mask: 'cnpj',
}, {
  label: 'Valor',
  id: 'valor',
  type: 'interval_range',
}, {
  label: 'Vencimento',
  id: 'data_vencimento',
  type: 'date_range',
}, {
  label: 'Dt. Importação',
  id: 'data_importacao',
  type: 'date',
}];

const VALUE_CARDS_DISPONIVEIS = (fornecedores: number, valor_total: number): ICard[] => [{
  id: 1,
  icon: 'uil:users-alt',
  subtitle: 'Forn. com títulos disponíveis',
  value: fornecedores,
  type: 'integer',
}, {
  id: 4,
  icon: 'uil:money-bill',
  subtitle: 'Valor disponível',
  value: valor_total,
  type: 'money',
}];

export { TABLE_COLUMNS_DISPONIVEIS, FILTER_OPTIONS_DISPONIVEIS, VALUE_CARDS_DISPONIVEIS };
