import React from 'react';

import { styled } from '@mui/material/styles';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import Header from '../components/Header';
import useAuth from '../hooks/useAuth';

const APP_BAR_MOBILE = 60;
const APP_BAR_DESKTOP = 80;

interface PrivateRouteProps extends RouteProps {
  component: any;
  path: string
}

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 20,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const { signed } = useAuth();

  return (
    <RootStyle>
      <Header />
      <MainStyle>
        <Route
          {...rest}
          render={(routeProps) => (
            signed ? <Component {...routeProps} /> : <Redirect to="/login" />
          )}
        />
      </MainStyle>
    </RootStyle>
  );
};

export default PrivateRoute;
