import React, { useCallback, useState } from 'react';

import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  InputAdornment, Stack, styled, TextField,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useAuth from '../../hooks/useAuth';

const TextFieldStyle = styled(TextField)(() => ({
  '&:hover': {
    transform: 'scale(102%)',
  },
  animation: '$fadeUpIn 3000ms ease-in-out',
  transition: 'all .3s ease-in-out',
}));

const LoadingButtonStyle = styled(LoadingButton)(() => ({
  '&:hover': {
    transform: 'scale(102%)',
  },
  animation: '$fadeUpIn 3000ms ease-in-out',
  transition: 'all .3s ease-in-out',
}));

const LoginAdmin = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const { signIn, loading } = useAuth();

  const LoginSchema = Yup.object().shape({
    email_sacado: Yup.string().email('E-mail do sacado precisa ser válido').required('E-mail do sacado é obrigatório'),
    email: Yup.string().email('E-mail precisa ser válido').required('E-mail é obrigatório'),
    password: Yup.string().required('Senha é obrigatório'),
  });

  const handleSignIn = useCallback((values) => {
    signIn(values, 'admin');
  }, [signIn]);

  const formik = useFormik({
    initialValues: {
      email_sacado: '',
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      handleSignIn(values);
    },
  });

  const {
    errors, touched, handleSubmit, getFieldProps,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ mb: 3 }}>
          <TextFieldStyle
            fullWidth
            autoComplete="email"
            type="email"
            label="E-mail do sacado"
            {...getFieldProps('email_sacado')}
            error={Boolean(touched.email_sacado && errors.email_sacado)}
            helperText={touched.email_sacado && errors.email_sacado}
          />

          <TextFieldStyle
            fullWidth
            autoComplete="email"
            type="email"
            label="E-mail admin"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextFieldStyle
            fullWidth
            autoComplete="password"
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? 'uil:eye' : 'uil:eye-slash'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <LoadingButtonStyle
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
        >
          Entrar
        </LoadingButtonStyle>
      </Form>
    </FormikProvider>
  );
};

export default LoginAdmin;
