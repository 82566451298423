import { createStore, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import { IConfigurationsState } from './ducks/configurations/types';
import rootReducer from './ducks/rootReducer';

export interface IApplicationState {
  configurations: IConfigurationsState,
}

export type RootState = ReturnType<typeof rootReducer>

const persistConfig = {
  key: 'persistor',
  storage,
  blacklist: ['session'],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer<RootState>(
  persistConfig,
  rootReducer,
);

export const store: Store<IApplicationState> = createStore(persistedReducer);
export const persistor = persistStore(store);
export default { store, persistor };
