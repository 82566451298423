import { alpha, Card, styled } from '@mui/material';

import { customShadows } from './theme/shadows';
import shape from './theme/shape';

export default styled(Card)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2, 0),
  textAlign: 'center',
  '&:hover': {
    transform: 'scale(102%)',
  },
  overflow: 'unset',
  animation: '$fadeUpIn 3000ms ease-in-out',
  transition: 'all .3s ease-in-out',
  color: theme.palette.primary.main,
  borderRadius: shape.borderRadiusMd,
  boxShadow: customShadows.z8,
}));
