/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';

import { Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import api from '../../api';
import shape from '../../assets/styles/theme/shape';
import { IUserVisualization } from '../../contexts/types';
import useAuth from '../../hooks/useAuth';
import useLocalStorage from '../../hooks/useLocalStorage';
import { cnpjFormat } from '../../services/Format';
import { SACADOS } from '../../utils/endpoints';
import MHidden from '../MHidden';
import Popover from '../Popover';
import MenuItem from './MenuItem';
import { BoxStyle, IconStyle } from './styles';

const VisualizationStyle = styled(Grid)(({ theme }) => ({
  overflow: 'auto',
  alignItems: 'center',
  borderRadius: shape.borderRadiusMd,
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  '&:hover': {
    cursor: 'pointer',
    transform: 'scale(102%)',
  },
  animation: '$fadeUpIn 3000ms ease-in-out',
  transition: 'all .3s ease-in-out',
  padding: 10,
  maxWidth: 240,
}));

const Visualization: React.FC = (): JSX.Element => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState<IUserVisualization[]>();

  const [
    _,
    setStorageUserVisualization,
  ] = useLocalStorage('@authApp:userVisualization');

  const {
    user, userVisualization, setUserVisualization,
  } = useAuth();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (userV: IUserVisualization) => {
    let selectedUser = userV;
    if (userV.razao_social === 'Todas as empresas') {
      selectedUser = { ...user, cnpj: user?.cnpj } as IUserVisualization;
    }
    setUserVisualization({
      ...selectedUser,
      razao_social: selectedUser.razao_social,
      grupo_id: selectedUser.grupo_id,
      cnpj: selectedUser.cnpj,
    });
    setStorageUserVisualization(selectedUser);
    handleClose();
  };

  const getUsers = () => {
    api.get(SACADOS)
      .then((res) => {
        const aux = res?.data.filter((item: any) => item.cnpj !== user?.cnpj);
        setUsers(res?.data ? aux : []);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const isSameDocument = user?.cnpj === userVisualization?.cnpj;

  return (
    <>
      <VisualizationStyle
        container
        alignContent="center"
        justifyContent="center"
        ref={anchorRef}
        onClick={handleOpen}
      >
        <IconStyle icon="mdi:eye-refresh" />
        <MHidden width="lgDown">
          <Typography noWrap variant="subtitle2">
            {isSameDocument ? 'Todas as empresas' : cnpjFormat(userVisualization?.cnpj)}
          </Typography>
        </MHidden>
      </VisualizationStyle>

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 400 }}
      >
        <BoxStyle>
          <Typography variant="subtitle1" noWrap>
            {isSameDocument ? 'Todas as empresas' : userVisualization?.razao_social}
          </Typography>
          <Typography variant="body2" noWrap>
            {cnpjFormat(userVisualization?.cnpj)}
          </Typography>
        </BoxStyle>

        <Divider />
        {users?.map((option) => (
          <MenuItem
            onClick={handleClick}
            option={option}
            icon="mdi:swap-horizontal"
          />
        ))}
        <Divider />
        <MenuItem
          onClick={handleClick}
          icon="mdi:restart"
          option={{
            cnpj: user?.cnpj || '',
            grupo_id: user?.grupo_id || '',
            id: user?.id || '',
            razao_social: 'Todas as empresas',
          }}
        />
      </Popover>
    </>
  );
};

export default Visualization;
