import React from 'react';

import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { styled, Tooltip } from '@mui/material';
import { saveAs } from 'file-saver';

import api from '../../api';
import CardStyle from '../../assets/styles/CardStyle';
import { customShadows } from '../../assets/styles/theme/shadows';
import shape from '../../assets/styles/theme/shape';
import useFilter from '../../hooks/useFilter';
import { showSuccess } from '../../services/Toast';
import { FiltersObject } from '../../types/filters';
import { EXPORT_CSV } from '../../utils/endpoints';

const LoadingButtonStyle = styled(LoadingButton)(() => ({
  width: '100%',
  height: '100%',
  borderRadius: shape.borderRadiusMd,
  boxShadow: customShadows.z8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface IProps {
  downloading: boolean;
  setDownloading: React.Dispatch<React.SetStateAction<boolean>>;
  aba: 0 | 1 | 2;
  filters: FiltersObject;
}

const Download = ({
  downloading, setDownloading, aba, filters,
}: IProps): JSX.Element => {
  const { getFilterQuery } = useFilter();

  const handleDownload = async () => {
    setDownloading(true);
    const filterParams = await getFilterQuery(filters);
    const csv = await api.get(`${EXPORT_CSV}?aba=${aba}&${filterParams}`);

    if (csv?.data) {
      const { data } = csv;
      const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });

      saveAs(csvData, 'Duplicatas.csv');
      showSuccess('Download realizado com sucesso.', {
        autoClose: 3500,
        hideProgressBar: false,
      });
    }

    setDownloading(false);
  };

  return (
    <CardStyle sx={{
      maxHeight: 50, height: 50, padding: 0, minWidth: 120, maxWidth: 250,
    }}
    >
      <Tooltip arrow title="Baixar">
        <LoadingButtonStyle
          loading={downloading}
          onClick={handleDownload}
        >
          <Icon width={26} icon="carbon:csv" style={{ marginRight: '2px' }} />
          <Icon width={26} icon="mdi:download" />
        </LoadingButtonStyle>
      </Tooltip>
    </CardStyle>
  );
};

export default Download;
