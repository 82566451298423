import React from 'react';

import { Grid, Typography } from '@mui/material';

import { IUserVisualization } from '../../contexts/types';
import { cnpjFormat } from '../../services/Format';
import { IconStyle, MenuItemStyle } from './styles';

interface IProps {
  option: IUserVisualization,
  onClick: (option: IUserVisualization) => void,
  icon: string
}

const MenuItem = ({ option, onClick, icon }: IProps): JSX.Element => (
  <MenuItemStyle
    key={option?.id}
    onClick={() => onClick(option)}
  >
    <Grid item>
      <IconStyle icon={icon} />
    </Grid>
    <Grid item style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
      <Typography variant="subtitle1" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {option?.razao_social}
      </Typography>
      <Typography variant="body2">
        {cnpjFormat(option?.cnpj)}
      </Typography>
    </Grid>
  </MenuItemStyle>
);

export default MenuItem;
