import { Icon } from '@iconify/react';
import {
  alpha,
  Button,
  Card,
  OutlinedInput,
  Select,
  styled,
} from '@mui/material';

import { customShadows } from '../../assets/styles/theme/shadows';
import shape from '../../assets/styles/theme/shape';

const CardStyle = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  overflow: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: 50,
  height: 50,
  color: alpha(theme.palette.primary.main, 0.72),
  maxWidth: '100%',
  '&:hover': {
    transform: 'scale(102%)',
  },
  animation: '$fadeUpIn 3000ms ease-in-out',
  transition: 'all .3s ease-in-out',
  '& fieldset': {
    borderWidth: '0px',
  },
  borderRadius: shape.borderRadiusMd,
  boxShadow: customShadows.z8,
}));

const OutlinedInputStyle = styled(OutlinedInput)(() => ({
  width: '100%',
  zIndex: 0,
  height: 50,
  maxHeight: 50,
}));

const ButtonStyle = styled(Button)(() => ({
  borderRadius: shape.borderRadiusMd,
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#FFF',
  },
}));

const SelectStyle = styled(Select)(() => ({
  width: '100%',
  minWidth: 100,
  height: 50,
}));

const DeleteIconStyle = styled(Icon)(() => ({
  marginRight: 10,
  '&:hover': {
    cursor: 'pointer',
  },
}));

export {
  CardStyle, OutlinedInputStyle, ButtonStyle, SelectStyle, DeleteIconStyle,
};
