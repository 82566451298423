import React from 'react';

import { CircularProgress } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay';
import './spinner.scss';

interface ISpinnerProps {
  active: boolean
}

const Spinner: React.FC<ISpinnerProps> = ({ active }: ISpinnerProps) => (
  <LoadingOverlay
    className="loading"
    active={active}
    styles={{
      overlay: (base) => ({
        ...base,
        background: 'rgba(0, 0, 0, 0.1)',
        position: 'fixed',
        top: 0,
        left: 0,
      }),
    }}
    spinner={(
      <div className="spinner-container">
        <CircularProgress className="spinner" />
      </div>
    )}
  />
);

export default Spinner;
