/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, {
  useCallback, useState,
} from 'react';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Spinner from '../../components/Spinner';
import { IResetPassword } from '../../contexts/types';
import useAuth from '../../hooks/useAuth';
import useTenant from '../../hooks/useTenant';
import { showError } from '../../services/Toast';
import { IApplicationState } from '../../store';
import { IConfiguration } from '../../store/ducks/configurations/types';
import './resetPassword.scss';

interface IStateProps {
  configurations: IConfiguration
  location: Location
}
interface IStyleProps {
  colorPrimary: string
}

const useStyles = makeStyles<Theme, IStyleProps>(() => ({
  root: {
    width: '100%',
    marginBottom: 16,
    '& label.Mui-focused': {
      color: ({ colorPrimary }) => colorPrimary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: ({ colorPrimary }) => colorPrimary,
    },
    '&.MuiOutlinedInput-root': {
      '& :hover fieldset': {
        borderColor: ({ colorPrimary }) => colorPrimary,
      },
      '& .Mui-focused fieldset': {
        borderColor: ({ colorPrimary }) => colorPrimary,
      },
    },
  },
}));

const ResetPassword: React.FC<IStateProps> = ({ configurations, location }) => {
  const classes = useStyles({ colorPrimary: configurations.colors.colorPrimary });
  const params = new URLSearchParams(location.search);

  const [reseted, setReseted] = useState(false);
  const { resetPassword, loading } = useAuth();

  const { logos: { logoWhite } } = useTenant();

  const history = useHistory();
  const [values, setValues] = useState<IResetPassword>({
    email: null,
    password: null,
    confirmPassword: null,
    token: params.get('token'),
  });

  const handleChange = (prop: keyof IResetPassword) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => setValues({ ...values, [prop]: event.target.value });

  const handleReset = useCallback(async () => {
    if (!isEmpty(values.email) && !isEmpty(values.password) && !isEmpty(values.confirmPassword)) {
      if (values?.confirmPassword !== values.password) {
        showError('As senhas devem ser iguais.');
        return;
      }
      const resp = await resetPassword(values);
      setReseted(!!resp);
    } else {
      setValues({ email: '', password: '', confirmPassword: '' });
      showError('Preenche os campos corretamente');
    }
  }, [resetPassword, values]);

  const goToLogin = () => history.push('/login');

  return (
    <div id="page-reset-password">
      <Spinner active={loading} />
      <aside>
        <img width="50%" src={logoWhite} alt="lOGO" />
      </aside>
      <main>
        <div className="main-content">
          <h2>RESETAR SENHA</h2>
          <form noValidate autoComplete="off">
            <TextField
              helperText={values.email === '' ? 'Campo obrigatório' : null}
              className={classes.root}
              onChange={handleChange('email')}
              required
              error={values.email === ''}
              label="E-mail"
              value={values.email}
            />
            <TextField
              helperText={values.password === '' ? 'Campo obrigatório' : null}
              className={classes.root}
              onChange={handleChange('password')}
              required
              type="password"
              error={values.password === ''}
              label="Senha"
              value={values.password}
            />
            <TextField
              helperText={values.confirmPassword === '' ? 'Campo obrigatório' : null}
              className={classes.root}
              onChange={handleChange('confirmPassword')}
              required
              type="password"
              error={values.confirmPassword === ''}
              label="Confirmação senha"
              value={values.confirmPassword}
            />
            <div className="buttons">
              <div className="login">
                {reseted && (
                  <span onClick={goToLogin}>
                    Ir para login
                  </span>
                )}
              </div>
              <button type="button" onClick={handleReset}>
                Continuar
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  configurations: state.configurations.configuration,
});

export default connect(mapStateToProps)(ResetPassword);
