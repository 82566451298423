import moment from 'moment';
import numeral from 'numeral';

export const moneyFormat = (value: any = 0): string => Intl.NumberFormat(
  'pt-br',
  { style: 'currency', currency: 'BRL' },
).format(value);

export const numberFormat = (
  number: string | number | null | undefined,
): string => numeral(number).format();

export const cnpjFormat = (cnpj: string | undefined): string => {
  if (cnpj) {
    return cnpj.replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }
  return '';
};

// remove acentos e caracteres especiais retornando a letra sem acento
// exemplo: í => i, ü => u, ã => a, etc...
export const normalizeString = (str: string): string => {
  const normalizedString = str.normalize('NFD').replace(/[\u0300-\u036f-0-9]/g, '');
  return normalizedString;
};

export const removeAllSpecialCharacters = (value: string): string => {
  const filtered = value.match(/[\p{L}\p{N}]/gu);
  return filtered ? filtered.join('') : '';
};

export const formatDateToISO8601 = (date: Date | string | null): string => (date ? moment(date).toISOString() : '');

export const decimalFormat = (value: string | number): string => `${value?.toString().replace('.', ',').replace('-', '')} %`;
