/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';

import { Box, Container, Grid } from '@mui/material';
import moment from 'moment';

import CardComponent from '../../components/Card';
import DataGrid from '../../components/DataGrid';
import Download from '../../components/Download';
import Filter from '../../components/Filters';
import Page from '../../components/Page';
import Spinner from '../../components/Spinner';
import Upload from '../../components/Upload';
import useDashboardData from '../../hooks/useDashboardData';
import { FilterOption, FiltersObject } from '../../types/filters';
import { ABA_NUMBER } from '../../utils/constants';

const Disponiveis: React.FC = (): JSX.Element => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [filters, setFilters] = useState<FiltersObject>({});

  const {
    TABLE_ROWS, TABLE_COLUMNS, FILTER_OPTIONS, VALUE_CARDS, isLoadingData,
  } = useDashboardData(ABA_NUMBER.DISPONIVEIS, filters);

  const handleAddFilter = (filters: FiltersObject) => {
    const today = moment().toDate();
    const previousDate = moment().subtract(30, 'days').toDate();

    const { value } = filters.periodo;
    const [initialDate, finalDate] = value as any;

    if (!initialDate || !finalDate) {
      // eslint-disable-next-line no-param-reassign
      filters.periodo.value = [previousDate, today];
    }

    setFilters(filters);
  };

  return (
    <Page title="Disponíveis">
      <Spinner active={isLoadingData || downloading} />
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            spacing={2}
            flex={1}
            width="100%"
            height="100%"
            display="flex"
            gap={2}
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            {VALUE_CARDS.map((card) => (
              <CardComponent card={card} isLoading={isLoadingData} />
            ))}

            <Upload
              uploading={uploading}
              setUploading={setUploading}
            />

          </Grid>

          <Grid
            item
            xs={12}
            spacing={2}
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Box flex={1}>
              <Filter
                handleAddFilter={handleAddFilter}
                filterOptions={FILTER_OPTIONS as FilterOption[]}
              />
            </Box>
            <Box>
              <Download
                aba={0}
                filters={filters}
                downloading={downloading}
                setDownloading={setDownloading}
              />
            </Box>
          </Grid>

          <DataGrid
            uploading={false}
            loading={isLoadingData}
            rows={TABLE_ROWS}
            columns={TABLE_COLUMNS}
          />

        </Grid>
      </Container>
    </Page>
  );
};

export default Disponiveis;
