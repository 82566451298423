import React, { useState } from 'react';

import {
  Box, Grid,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import useTenant from '../../hooks/useTenant';
import MHidden from '../MHidden';
import Settings from './Settings';
import {
  AppBarStyle, imageStyle, LinkTabStyle, TabsStyle, ToolbarStyle,
} from './styles';
import Visualization from './Visualization';

const Navbar: React.FC = (): JSX.Element => {
  const [value, setValue] = useState(0);

  const history = useHistory();

  const { user } = useAuth();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleLogoClick = () => {
    setValue(0);
    history.push('/dashboard');
  };

  const { logos: { logoWhite } } = useTenant();

  return (
    <AppBarStyle>
      <ToolbarStyle>
        <Grid container alignItems="center">
          <MHidden width="lgDown">
            <Grid item lg={1} sm={1}>
              <Box
                onClick={handleLogoClick}
                component="img"
                src={logoWhite}
                alt="lOGO"
                sx={imageStyle}
              />
            </Grid>
          </MHidden>
          <Grid item xs={10} lg={8} sm={9}>
            <TabsStyle
              TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
              value={value}
              onChange={handleChange}
              aria-label="nav tabs"
            >
              <LinkTabStyle label="Disponíveis" href="dashboard" />
              <LinkTabStyle label="Negociações" href="negociacoes" />
              <LinkTabStyle label="Importações" href="importacoes" />
              <LinkTabStyle label="Arquivados" href="arquivados" />
            </TabsStyle>
          </Grid>
          <Grid item xs={1} lg={2} sm={2}>
            {user?.super && <Visualization />}
          </Grid>
          <Grid display="flex" item xs={1} lg={1} sm={1} justifyContent="center">
            <Settings />
          </Grid>
        </Grid>
      </ToolbarStyle>
    </AppBarStyle>
  );
};

export default Navbar;
