import { useContext } from 'react';

import TenantContext, { ITenantContext } from '../contexts/tenant';

const useTenant = (): ITenantContext => {
  const tenant = useContext(TenantContext);
  return tenant;
};

export default useTenant;
