import React from 'react';

import { WarningRounded } from '@mui/icons-material';
import {
  getGridStringOperators, GridColDef, GridRenderCellParams, GridValueFormatterParams,
} from '@mui/x-data-grid';
import moment from 'moment';

import StatusFlagImports from '../../components/StatusFlag/StatusFlagImports';
import Actions from '../../pages/Imports/Actions';
import { FilterOption } from '../../types/filters';

const TABLE_COLUMNS_IMPORTS: GridColDef[] = [{
  field: 'arquivo_nome',
  headerName: 'Arquivo',
  align: 'center',
  flex: 1,
  headerAlign: 'center',
  filterable: true,
  valueFormatter: ({ value }: GridValueFormatterParams) => (value ?? '-'),
  filterOperators: getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf'),
}, {
  field: 'iniciado',
  headerName: 'Importado em',
  width: 150,
  align: 'center',
  headerAlign: 'center',
  valueFormatter: ({ value }: GridValueFormatterParams) => (value ? moment(value as string).format('DD/MM/YY') : '-'),
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
}, {
  field: 'finalizado',
  headerName: 'Finalizado em',
  width: 150,
  align: 'center',
  headerAlign: 'center',
  valueFormatter: ({ value }: GridValueFormatterParams) => (value ? moment(value as string).format('DD/MM/YY') : '-'),
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
}, {
  field: 'status',
  headerName: 'Status',
  width: 150,
  align: 'center',
  headerAlign: 'center',
  disableColumnMenu: true,
  renderCell: (params) => (
    <StatusFlagImports status={params.value} />
  ),
}, {
  field: 'possui_critica',
  headerName: 'Possui Críticas',
  width: 150,
  align: 'center',
  headerAlign: 'center',
  disableColumnMenu: true,
  renderCell: ({ value }: GridRenderCellParams) => (
    <WarningRounded
      color={value ? 'error' : 'disabled'}
    />
  ),
}, {
  field: 'acoes',
  headerName: 'Ações',
  disableColumnMenu: true,
  disableReorder: true,
  align: 'center',
  headerAlign: 'center',
  renderCell: (row) => (
    <Actions row={row} />
  ),
},
];

const FILTER_OPTIONS_IMPORTS: FilterOption[] = [{
  label: 'Importado em',
  id: 'importado_em',
  type: 'date_range',
}];

export {
  TABLE_COLUMNS_IMPORTS, FILTER_OPTIONS_IMPORTS,
};
